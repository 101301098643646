<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.complex')"
            :placeholder="$t('placeholder.select_complex')"
            :value="complexes.find(el => el.id === form.complex_id)"
            :options="complexes"
            required
            @select="form.complex_id = $event.id"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-multiselect
            :label="$t('label.block')"
            :placeholder="$t('placeholder.select_block')"
            :value="blocks.find(el => el.id === form.block_id)"
            :options="blocks"
            required
            :disabled="!form.complex_id"
            @select="form.block_id = $event.id"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.name')"
            v-model="form.name"
            :placeholder="$t('placeholder.enter_name')"
            required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppInput,
    AppMultiselect
  },
  props: {
    blockId: {
      default: null
    },
    office: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      complexId: null,
      updatePageFlag: false,
      form: {
        complex_id: null,
        block_id: null,
        name: null
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('complexesStore/GET_LIST');
    this.$store.dispatch('blocksStore/GET_BLOCK', this.blockId);
  },
  computed: {
    ...mapGetters({
      complexes: 'complexesStore/COMPLEXES',
      blocks: 'blocksStore/BLOCKS',
      block: 'blocksStore/BLOCK'
    }),
    computedFormComplexId() {
      return this.form.complex_id;
    }
  },
  watch: {
    block(data) {
      if (data) {
        this.form.complex_id = data.complex.id;
      }
    },
    blocks() {
      this.form.block_id = this.blockId;
    },
    computedFormComplexId(id) {
      this.$store.dispatch('blocksStore/GET_BLOCKS', {complex_id: id})
    },
    office(data) {
      if (data) {
        this.updatePageFlag = true;

        this.form.complex_id = data.complex.id;
        this.form.block_id = data.block_id;
        this.form.name = data.name;
      }
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  }
}
</script>